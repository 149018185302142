
<template>
  <div v-loading = "pageloading">
    <el-form :model="ruform" :rules="rules" ref="ruform" label-width="120px" style="margin-bottom:100px">
      <el-card>
        <div class = "cardTitle">帐号设置</div>
        <div class = "flexRow" style="align-items:stretch;">
          <div>
            <el-form-item label="名称：" prop="Name">
              <el-input v-model="ruform.Name" placeholder="请输入名称，最多16个字" maxlength="16" style="width:500px"></el-input>
              <div class = "grayfont">
                该名称仅用于备注，方便查找，不会显示在会话界面
              </div>
            </el-form-item>
            <el-form-item label="引导语：">
              <el-input type="textarea" v-model="ruform.GuideContent" style="width:500px" maxlength="100" show-word-limit :rows="5"
              placeholder="请设置引导语，若不填，将使用系统默认文案：您好，为减少您的等待时间，请选择您要咨询的内容："></el-input>
            </el-form-item>
            <el-form-item label="菜单配置：" class="havestar">
              <div class="menu" v-for="(v,i) in ruform.DetailList" :key="v.key">
                <div class = "menutop flexRow" style="justify-content: space-between;">
                  <span>菜单{{i+1}}</span>
                  <div>
                    <el-button v-if="ruform.DetailList.length>1" type="text" style="color:#f56c6c;margin:0px;padding:0px" @click="delmenu(i)">删除</el-button>
                  </div>
                </div>
                <div>
                  <el-form-item label="标题：" :prop="'DetailList.'+i+'.Title'" :rules="rules.Title" style="margin:20px 0px">
                    <el-input v-model="v.Title" placeholder="请输入标题，最多50个字" maxlength="50"></el-input>
                  </el-form-item>
                  <el-form-item label="点击动作：" :prop="'DetailList.'+i+'.ClickActionType'" :rules="rules.ClickActionType" style="margin:20px 0px">
                    <div class ="grayfont" style="margin-top:10px">
                      客户点击菜单后，发送消息、跳转链接、进入小程序页面等动作
                    </div>
                    <div>
                      <el-radio-group v-model="v.ClickActionType" @change="(e)=>{radiochange(e,i)}">
                        <el-radio :label="1" class = "radiomargin">发送图文消息</el-radio>
                        <el-radio :label="2" class = "radiomargin">跳转网页链接</el-radio>
                        <el-radio :label="3" class = "radiomargin">进入商城小程序</el-radio>
                        <br>
                        <el-radio :label="4" class = "radiomargin">回复菜单消息</el-radio>
                        <el-radio :label="5" class = "radiomargin">联系专属顾问</el-radio>
                        <el-radio :label="6" class = "radiomargin">接入人工服务</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                  <div v-if="v.ClickActionType == 1">
                    <el-form-item label-width="0" class = "errfont" :prop="'DetailList.'+i+'.img'" :key="'radio1'+i" :rules="rules.atleast">
                      <el-form-item label="文本消息：" class = "nostar" style="margin:20px 0px">
                        <el-input v-model="v.TextContent" type="textarea" maxlength="100" show-word-limit :rows="5"
                        placeholder="请输入要回复的文本消息"></el-input>
                      </el-form-item>
                      <el-form-item label="图片：" class = "nostar" style="margin:20px 0px">
                        <div class = "avatar" v-if="v.ImgUrl">
                          <img :src="imgurl+v.ImgUrl" class="avatar-img" alt="">
                          <div class = "avatar-mask">
                            <div class = "avatar-mask-content">
                              <i class = "el-icon-delete-solid" @click="delimg(i)" style="color:white;cursor: pointer;font-size:20px"></i>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <el-upload :action="imgapi" class="avatar-uploader" list-type="picture-card" :show-file-list = "false"
                            :on-success="($event)=>{return handleAvatarSuccess($event,i)}" accept="image/*"
                            :before-upload="($event)=>{return beforeAvatarUpload($event,i)}">
                            <i class="el-icon-plus"></i>
                          </el-upload>
                        </div>
                      </el-form-item>
                    </el-form-item>
                  </div>
                  <div v-if="v.ClickActionType == 2">
                    <el-form-item label="网页链接：" :key="'radio2'+i" :prop="'DetailList.'+i+'.LinkUrl'" :rules="rules.LinkUrl">
                      <el-input v-model="v.LinkUrl" placeholder="请输入链接地址"></el-input>
                    </el-form-item>
                  </div>
                  <div v-if="v.ClickActionType == 3">
                    <el-form-item label="选择页面：" :key="'radio3'+i" :prop="'DetailList.'+i+'.LinkName'" :rules="rules.LinkName">
                      <el-button type="text" @click="shopVisible=true,chooseindex=i">
                        <div class = "flexRow">
                          <span class="fonthidden" style="max-width:200px" v-if="v.LinkName">{{v.LinkName}}</span> 
                          <span v-else>选择链接页面</span>
                          <i class = "el-icon-arrow-down" style="margin-left:5px"></i>
                        </div>
                      </el-button>
                    </el-form-item>
                  </div>
                  <div v-if="v.ClickActionType == 4">
                    <el-form-item label="选择菜单消息：" :key="'radio4'+i" :prop="'DetailList.'+i+'.ChildQyWeixinKfMenuMessageId'" :rules="rules.ChildQyWeixinKfMenuMessageId">
                      <el-select v-model="v.ChildQyWeixinKfMenuMessageId" placeholder="请选择菜单消息" style="width:100%">
                        <el-option v-for="(v,i) in menulist" :key="v.Id" :value="v.Id" :label="v.Name"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-if="v.ClickActionType == 5">
                    <el-form-item label="联系专属顾问：" class = "nostar">
                      <div style="color:#606266;font-size:14px">客户点击后，系统自动发送客户锁粉店员企业微信二维码</div>
                      <el-button type="text" style="margin:0px;padding:0px" @click="jump(1)">如果客户没有锁粉店员？</el-button>
                    </el-form-item>
                  </div>
                  <div v-if="v.ClickActionType == 6">
                    <el-form-item label="接入人工服务：" class = "nostar">
                      <div style="color:#606266;font-size:14px">客户点击后，将自动接入人工服务排队池，需要接待人员手动接入</div>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class = "menu" v-if="ruform.DetailList.length<10">
                <div class = "menutop">
                  <el-button type="text" style="margin:0px;padding:0px" @click="pushmenu">添加菜单</el-button>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class = "positionbox" style="width:320px;margin-left:50px;min-height:700px">
            <!-- menumessageeditimgheader -->
            <img :src="imgurl+'/image/menumessageeditimg.png'" style="width:320;height:692px" class = "positionimg" alt="">
            <div class = "positioncontent">
              <div class = "demobox">
                <img :src="imgurl+'/image/menumessageeditimgheader.png'" class = "demoimg" alt="">
                <div class="fontframe">
                  <div class = "triangle"></div>
                  <div class = "fontcontent">
                    <div class = "font" style="color:#303133">{{ruform.GuideContent}}</div>
                    <div class = "font" v-for="(v,i) in ruform.DetailList" :key="i">
                      {{v.Title}}
                      <br>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </el-form>
    <el-card class="bottombox">
      <el-button @click="cancal">取消</el-button>
      <el-button type="primary" @click="save('ruform')">保存</el-button>
    </el-card>

    <linkModal noservice :showLiveTab="true" :visible="shopVisible" @change="dataChange" @cancel="shopVisible=false"></linkModal>
  </div>
</template>

<script>
import config from "@/config"
import linkModal from '@/views/components/linkModal';
import{
  qyWeixinKfMenuMessageedit,
  qyWeixinKfMenuMessageinfo,
  qyWeixinKfMenuMessagelist
}from "@/api/sv3.0.0"
export default {
  components: {
    linkModal
  },
  data () {
    var checkatleast = (rule, value, callback) => {
      let num = parseInt(rule.field.replace('DetailList.',''))
      if(!this.ruform.DetailList[num].TextContent && !this.ruform.DetailList[num].ImgUrl){
        callback(new Error('文本消息和图片不可同时为空'))
      }else{
        callback()
      }
    };
    return {
      pageloading:false,
      imgapi:config.UPLOAD_IMG,
      imgurl:config.IMG_BASE,
      ruform:{
        Id:0,
        Name:'',
        GuideContent:'您好，为减少您的等待时间，请选择您要咨询的内容：',
        DetailList:[{
          QyWeixinKfMenuMessageId:0,
          Title:'',
          ClickActionType:1,
          SortAsc:0,
          TextContent:'',
          ImgUrl:'',
          LinkUrl:'',
          LinkName:'',
          ChildQyWeixinKfMenuMessageId:null,
          key:Math.random()
        }],
      },
      rules:{
        Name:[
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],
        Title:[
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        ClickActionType:[
          { required: true, message: '请选择点击动作', trigger: 'blur' },
        ],
        atleast:[
          { validator: checkatleast, trigger: 'blur' }
        ],
        LinkName:[
          { required: true, message: '请选择链接页面', trigger: 'blur' },
        ],
        LinkUrl:[
          { required: true, message: '请输入网页链接地址', trigger: 'blur' },
        ],
        ChildQyWeixinKfMenuMessageId:[
          { required: true, message: '请选择菜单消息', trigger: 'blur' },
        ]
      },
      shopVisible:false,
      chooseindex:0,
      menulist:[],
    }
  },
  created () {
    if(Number(this.$route.query.Id)){
      this.getinfo()
    }
    this.gettablelist()
  },
  methods: {
    async getinfo(){
      try{
        this.pageloading = true
        let res = await qyWeixinKfMenuMessageinfo({Id:this.$route.query.Id})
        if(res.IsSuccess){
          let ruform = res.Result
          ruform = {
            ...res.Result.QyWeixinKfMenuMessage,
            DetailList:res.Result.QyWeixinKfMenuMessageDetailList
          }
          ruform.DetailList = ruform.DetailList.map((v)=>{
            v.ChildQyWeixinKfMenuMessageId = v.ChildQyWeixinKfMenuMessageId==0?null:v.ChildQyWeixinKfMenuMessageId
            return v
          })
          this.ruform = ruform
          // console.log(res.Result)
        }
      }finally{
        this.pageloading = false
      }
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Skip:0,
          Take:99999,
        }
        let res = await qyWeixinKfMenuMessagelist(data)
        if(res.IsSuccess){
          this.menulist = res.Result.Results
        }
      }finally{
        this.tableloading = false
      }
    },
    radiochange(e,i){
      this.ruform.DetailList[i].TextContent = ''
      this.ruform.DetailList[i].ImgUrl = ''
      this.ruform.DetailList[i].LinkUrl = ''
      this.ruform.DetailList[i].LinkName = ''
      this.ruform.DetailList[i].ChildQyWeixinKfMenuMessageId = ''
    },
    jump(e){
      if(e==1){
        window.open('https://jusnn6k8al.feishu.cn/docs/doccnrqpVQ5A0QerfuZw0QT3bkb')
      }
    },
    dataChange(e){
      this.ruform.DetailList[this.chooseindex].LinkUrl = this.getLinkUrl(e)
      this.ruform.DetailList[this.chooseindex].LinkName = e.text
      // console.log(this.getLinkUrl(e))
      this.shopVisible = false
    },
    getLinkUrl(record) {
      if (record.type == "classify") {
        return 'pages/productGroup/productGroup.html?id=' + record.data.Id
      } else if (record.type == "functional") {
        // 全部商品
        if (record.data.id == 1) {
          return 'pages/index/index.html'
        } else if (record.data.id == 2) {
          return 'pages/allProduct/allProduct.html'
        } else if (record.data.id == 3) {
          // 个人中心
          return 'pages/personalCenter/mine/mine.html'
        } else if (record.data.id == 4) {
          // 购物车
          return 'pages/shoppingCart/shoppingCart.html'
        } else if (record.data.id == 5) {
          // 积分中心
          return 'pages/pointsCenter/pointsCenter.html'
        } else if (record.data.id == 6) {
          // 每日签到
          return 'pages/userSign/userSign.html'
        } else if (record.data.id == 7) {
          // 客服
          return 'pages/customerService/customerService.html'
        } else if (record.data.id == 8) {
          // 订单列表
          return 'pages/order/myOrder/myOrder.html'
        } else if (record.data.id == 9) {
          // 分销礼包
          return 'pages/invitation/invitationCard/invitationCard.html'
        } else if (record.data.id == 10) {
          // 成为会员
          return 'pageA/pages/openSuccess/openSuccess.html'
        } else if (record.data.id == 11) {
          // 助力直播列表
          return 'pageA/pages/live/live.html'
        } else if (record.data.id == 12) {
          // 扫码购
          return 'pageA/pages/scan/scan.html'
        } else if (record.data.id == 13) {
          // 充值中心
          return 'pageA/pages/balanceRecharge/balanceRecharge.html'
        } else if (record.data.id == 14) {
          // 群分享
          return 'pageA/pages/groupShare/groupShare.html'
        } else if (record.data.id == 15) {
          // 全部信息页
          return 'pageA/pages/information/information.html'
        }else if (record.data.id == 16) {
          // 短视频带货
          return 'pageC/pages/shortVideos/record.html'
        }else if (record.data.id == 17) {
          // 积分码
          return 'pageA/pages/receive-point/receive-point'
        }else if (record.data.id == 18) {
          // 舌诊
          return 'pageC/pages/tongueDiagnosis/home'
        }else if (record.data.id == 19) {
          // 企店即创主页
          return 'pageC/pages/AIGC/home'
        }
      } else if (record.type == "product") {
        return 'pages/detail/detail.html?id=' + record.data.Id
      } else if (record.type == "custom") {
        return 'pages/cusPage/cusPage.html?id=' + record.data.Id
      } else if (record.type == 'truntable') {
        return 'pageA/pages/activity/truntable/truntable.html?id=' + record.data.Id
      } else if (record.type == 'live') {
        return 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin.html?room_id=' + record.data.RoomId
      } else if (record.type == 'coupon') {
        return 'pages/couponCenter/receive/receive.html?id=' + record.data.Id + '&uid=' + (record.data.uid || '')
      } else if (record.type == 'help') {
        return 'pages/helpDetail/helpDetail.html?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
      } else if (record.type == 'group') {
        return 'pages/groupDetail/groupDetail.html?id=' + record.data.ProductId + '&fullId=' + record.data
          .ActivityFullId
      } else if (record.type == 'questionnaire') {
        return 'pageA/pages/questionnaire/questionnaire.html?&referer=5&id=' + record.data.Id;
      }else if (record.type == 'taketest') {
        return `pageC/pages/goodnessTest/home?id=` + record.data.Id
      }else if (record.type == 'contentgroup') {
        return `pageA/pages/information/informationGroup?id=` + record.data.Id;
      }else if (record.type == 'singlecontent') {
        return `pageA/pages/information/detail?id=` + record.data.Id;
      }else if (record.type == 'helpPro') {
        return `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId;
      }
      return ''
    },
    delimg(e){
      this.ruform.DetailList[e].ImgUrl = ''
    },
    handleAvatarSuccess(file,i){
      // console.log(file,i)
      this.ruform.DetailList[i].ImgUrl = file[0]
    },
    beforeAvatarUpload(file){
      const size = file.size / 1024 / 1024 < 2;
      const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
        'image/webp'
      ]
      if (testtype.indexOf(file.type) == -1) {
        this.$message.error('请选择图片上传');
        return false
      } else if (!size) {
        this.$message.error('图片大小请控制在2M以内');
        return false
      }
    },
    delmenu(e){
      this.ruform.DetailList.splice(e,1)
    },
    pushmenu(){
      this.ruform.DetailList.push({
          QyWeixinKfMenuMessageId:0,
          Title:'',
          ClickActionType:1,
          SortAsc:0,
          TextContent:'',
          ImgUrl:'',
          LinkUrl:'',
          LinkName:'',
          ChildQyWeixinKfMenuMessageId:null,
          key:Math.random()
        })
    },
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tosave()
          // console.log(123)
        } else {
          return false;
        }
      });
    },
    async tosave(){
      try{
        this.pageloading = true
        let ruform = JSON.parse(JSON.stringify(this.ruform))
        ruform.DetailList.map((v,i)=>{
          v.SortAsc = i+1
          return v
        })
        if(!ruform.GuideContent){
          ruform.GuideContent = '您好，为减少您的等待时间，请选择您要咨询的内容：'
        }
        let res = await qyWeixinKfMenuMessageedit(ruform)
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.cancal()
        }
      }finally{
        this.pageloading = false
      }
    },
    cancal(){
      this.$router.go(-1)
    }
  }
}
</script>

<style lang = "less" scoped>
.bottombox{
  position: fixed;
  bottom: 0px;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  justify-content: center;
  margin-left: -8%;
  z-index: 999;
}
.cardTitle{
  font-size: 16px;
  /* font-weight: bold; */
  color: #303133;
  margin-bottom: 20px;
}
.grayfont{
  font-size: 12px;
  color: #999999;
  line-height: 1.5;
}
.flexRow{
  display: flex;
  align-items: center;
}
.havestar ::v-deep .el-form-item__label::before{
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
  
}
.nostar ::v-deep .el-form-item__label::before{
  content: '';
}
.errfont ::v-deep .el-form-item__error{
  left: 100px !important;
}
.menu{
  margin-bottom: 30px;
  .menutop{
    background: #F8F8F9;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    /* border: 1px solid #EBEEF5; */
    padding: 8px 20px;
    font-size: 14px;
    color: #606266;
    line-height: 1.5;
    width: 600px;
  }
  .radiomargin{
    margin: 10px 30px 0px 0px;
  }
  .avatar{
    width:148px;height: 148px;
    /* border:1px solid black; */
    position: relative;
    top: 0px;
    left:0px;
    .avatar-img{
      position: relative;
      top: 0px;
      left:0px;
      width:100%;height: 100%;
      z-index: 400;
    }
    .avatar-mask{
      position: absolute;
      top: 0px;
      left: 0px;
      background: rgba(0, 0, 0, .3);
      z-index: 500;
      width: 100%;
      height: 100%;
      pointer-events: auto;
      display: none;
      border-radius: 3px;
    }
    .avatar-mask-content{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .avatar:hover{
    .avatar-mask{
      display: block;
    }
  }
}
.fonthidden{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
</style>
<style lang="less" scoped>
.positionbox{
  position: relative;
  .positionimg{
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .positioncontent{
    position: absolute;
    top: 70px;
    left: 0px;
    // border:1px solid black;
    width: 100%;
    height: 532px;
    overflow: hidden;
    overflow-y: auto;
    .demobox{
      display: flex;
      align-items: flex-start;
      .demoimg{
        width: 32px;height: 32px;
        margin: 15px 0px 0px 10px;
      }
      .fontframe{
        display: flex;
        // border:1px solid black;
        margin: 15px 0px 0px 5px;
        .fontcontent{
          background: white;
          padding: 7px 10px;
          border-radius: 3px;
          // border:1px solid #c4d8f0;
          min-height: 37px;
          min-width: 32px;
          max-width: 220px;
          .font{
            font-size: 14px;
            color: #409EEF;
            line-height: 1.5;
          }
        }
        .triangle{
          position: relative;
          top: 10px;
          // border:1px solid black;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-right-color: white ;
        }
      }
    }
  }
}
</style>